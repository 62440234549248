@import url('https://fonts.googleapis.com/css2?family=Fira+Sans+Condensed:ital,wght@0,400;0,600;1,700&display=swap');

.landing-page{
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-content: center;
    margin: auto;
    width: 100%;
    min-height: 650px;
    background-color:#F3FBFF ;
}

.get-started-logo{
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    align-self: center;
    width: fit-content;

    svg{
        align-self: center;
    }

    p{
        text-align: center;
        font-style: normal;
        font-weight: 700;
        font-size: 35px;
        line-height: 105%;
        color: #003850;
    }
}

#cloud-info{
    animation: animate 3s ;
}

.get-started-btn{
    width: fit-content;
    align-self: center;
    background: #003850;
    border-radius: 30px;
    text-decoration: none;
    color: #fff;
    padding: 1.1rem 2.2rem;
    cursor: pointer;
    transition: 0.2s;
    box-shadow: 0 2px 12px #0000006c;
    animation: grow 3s infinite;

    &:hover{
        background: #fff;
        color: #003850;
        box-shadow: 0 4px 16px #0000006c;
    }
}


@keyframes grow{
    0%{
        transform: scale(1);
    }
    50%{

        transform: scale(1.1);
        
    }
    100%{

        transform: scale(1);
        
    }
}