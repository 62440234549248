.container {
    width: 20vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-image: url(../../assets/circle.png);
    background-position: -2vw bottom;
    background-repeat: no-repeat;
    padding-bottom: 20px;
    // visibility: visible;
    padding-top: 20px;
    transition: all 1s ease-in;
    background-color: rgba(0, 155, 192, 1);

    @media screen and (max-width: 1200px) {
        width: 0px;
        z-index: 2;
        position: absolute;
        padding: 20px 0;
        left: 0;

        .menu {
            display: none;
        }

        .profile {
            display: none;
        }

        .logos {
            display: none;
        }

        // visibility: hidden;
        &.active {
            width: 70%;

            @media screen and (min-width: 576px) {
                width: 40%
            }

            @media screen and (min-width: 800px) {
                width: 30%
            }

            // visibility: visible;
            .menu {
                display: block;
            }

            .profile {
                display: block;
            }

            .logos {
                display: flex;
                height: 200px;
                align-items: flex-end;
            }
        }

        .close-menu-btn {
            position: absolute;
            top: 16px;
            right: 15px;
            width: 25px;
            cursor: pointer;

            .bar {
                border: 2px solid #003850;
                border-radius: 20px;
                height: 2px;
                width: 22px;
                margin: auto;
            }

            .bar-1 {
                transform: rotateZ(45deg) translateZ(24px);
            }

            .bar-3 {
                transform: rotateZ(-45deg) translateX(3px) translateY(-2px);
            }

        }
    }

    .profile {

        .logo2 {
            display: flex;
            // position: absolute;
            // top: 10px;
            flex-direction: column;
            align-content: center;
            justify-content: center;
            background: linear-gradient(0deg, #F3FBFF, #F3FBFF), #C4C4C4;
            border-radius: 30px;
            margin: 0 20px 0 20px;
            padding: 2rem 3rem;
            // width: 100%;
            box-shadow: 0 3px 6px #00000012;

            // img{
            //     max-width: 35px;
            //     max-height: 35px;
            // }
            @media screen and (max-width: 1200px) {
                width: 200px;
            }

            h3 {
                font-family: 'CharlieText-Bold';
                font-style: normal;
                font-weight: 700;
                font-size: 30px;
                line-height: 98.5%;
                text-align: center;
                color: #003850;
            }
        }
    }

    .menu {

        ul {
            display: flex;
            flex-direction: column;
            // background-color: red;
        }

        .nav-item {
            display: flex;
            flex-direction: row;
            align-items: center;
            font-family: 'CharlieText-Regular';
            list-style: none;
            // padding: 0.4rem;
            margin: 0rem 0 0.4rem 0rem;
            cursor: pointer;
            color: rgba(255, 255, 255, 0.7);
            transition: 0.2s;

            &:hover {
                transform: scale(105%);
            }

            span {

                align-self: center;
                margin: 0 0 0 0.5rem;
                font-weight: 600;
                font-size: 22px;

                // line-height: 20px;
                &:hover {
                    font-weight: 900;
                }
            }

            img {
                max-width: 35px;
                max-height: 35px;
            }

            &.active {
                font-family: 'CharlieText-Semibold';
                // background: rgba(3, 159, 196, 0.25);
                // border-radius: 0 30px 30px 0;
                // padding: 1rem;
                // border-left: 12px solid #039fc440;
                font-style: normal;
                font-weight: 700;
                // font-size: 24px;
                color: rgb(255, 255, 255);
                // box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
            }
        }
    }

    .logos {
        display: flex;
        height: 200px;
        align-items: flex-end;
    }
}

.mobile-container {
    width: 100vw;
    position: relative;


}