@font-face {
    font-family: 'CharlieText-Regular';
    src: url('../../assets/Charlie__Atlassian/CharlieText-Regular.ttf');
}

@font-face {
    font-family: 'CharlieText-Bold';
    src: url('../../assets/Charlie__Atlassian/CharlieDisplay-Bold.ttf');
}

@font-face {
    font-family: 'CharlieText-Semibold';
    src: url('../../assets/Charlie__Atlassian/CharlieDisplay-Semibold.ttf');
}

@import '../../breakpoints.scss';

.homepage {
    display: flex;
    flex-direction: column;
    font-family: 'CharlieText-Regular';

    th {
        font-family: 'CharlieText-Bold';
    }
}

.loading-screen {
    display: flex;
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    width: 100%;
    justify-content: space-between;
    align-content: center;
    min-height: 400px;
    z-index: 10;
}

.hide-loading-screen {
    animation: fadeOut 4s;
}

.loader {
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    margin: 1rem auto;
    width: 100%;
    max-width: 500px;


    img {
        margin: 1rem auto;
        align-self: center;
        animation: grow 3s infinite;
    }

}



main {
    display: flex;
    flex-direction: row;
    padding: 1rem;

    @include media("<tablet") {
        padding: 0;
    }
}

.banner-div {
    display: block;
    transition: 0.3s;
}

.hide {
    display: none;
}

.eVYBsl {
    max-height: 70px !important;
    width: 100%;
}

.menu-btn {
    display: none;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    position: fixed;
    background-color: #003850;
    width: 40px;
    height: 40px;
    margin: auto;
    z-index: 5;
    border-radius: 5px;
    cursor: pointer;
    box-shadow: 0 3px 6px #00000012;

    @include media("<phone") {
        display: flex;
    }

    @include media(">phone", "<custom-view") {
        display: flex;
    }

    .bar {
        border: 2px solid #fff;
        border-radius: 20px;
        height: 3px;
        width: 80%;
        margin: auto;
    }

    .bar-2 {
        margin: 0 auto;
    }
}

.text-menu {
    display: none;
    position: fixed;
    width: fit-content;
    padding: 0.6rem 2rem;
    transform: rotateZ(90deg);
    left: -41px;
    height: auto;
    top: 300px;
    color: #fff;
    border-radius: 5px 5px 0 0;
    background-color: #003850;
    margin: auto;
    z-index: 5;
    cursor: pointer;
    box-shadow: 3px 0 6px #0000008c;

    @media screen and (max-width: 1200px) {
        display: block;
    }

    // @include media(">phone", "<custom-view") {
    //     display: block;
    // }
}

.text-menu-2 {
    display: none;
    position: fixed;
    width: fit-content;
    padding: 0.6rem 2rem;
    transform: rotateZ(90deg);
    right: -41px;
    height: auto;
    top: 300px;
    color: #fff;
    border-radius: 0 0 5px 5px;
    background-color: #003850;
    margin: auto;
    z-index: 5;
    cursor: pointer;
    box-shadow: 3px 0 6px #0000008c;

    @media screen and (max-width: 576px) {
        display: block;
    }
}

.hide-btn {
    display: none;
}

.mobile-sidemenu {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    width: 30%;
    max-width: 280px;
    min-width: 270px;
    height: 600px;
    position: fixed;
    background-color: #E3EDFF;
    border-radius: 0 30px 30px 0;
    left: 0;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.445);
    transform: translateX(-1000px);
    transition: 0.3s;
    z-index: 5;

    .logo {
        position: unset;
        background: none !important;
        box-shadow: none;
    }

    .navigation {
        background: none !important;
        position: unset;
        top: auto;
        box-shadow: none;

        .nav-item {
            color: #F3FBFF;
        }
    }

    .active {
        font-family: 'CharlieText-Semibold';
        background: rgba(0, 56, 80, 0.25);
        border-radius: 0 30px 30px 0;
        padding: 0.9rem;
        border-left: 12px solid #003850;
        font-style: normal;
        font-weight: 600;
        color: #003850;
    }
}

.show {
    transform: translateX(0);
}

.close {
    transform: translateX(-1000px);
}

.desktop-sidemenu {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    width: 30%;
    max-width: 270px;
    position: relative;

    @include media("<phone") {
        display: none;
    }

    @include media(">phone", "<custom-view") {
        display: none;
    }


}

.logo-div {
    position: relative;
}

.logo {
    display: flex;
    position: absolute;
    top: 10px;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    background: linear-gradient(0deg, #F3FBFF, #F3FBFF), #C4C4C4;
    border-radius: 30px;
    padding: 3rem 5rem;
    width: 100%;
    box-shadow: 0 3px 6px #00000012;

    h3 {
        font-family: 'CharlieText-Bold';
        font-style: normal;
        font-weight: 700;
        font-size: 30px;
        line-height: 98.5%;
        text-align: center;
        color: #003850;
    }
}

.navigation {
    background: linear-gradient(0deg, #F3FBFF, #F3FBFF), #C4C4C4;
    border-radius: 30px;
    padding: 1rem 1rem 1rem 0;
    margin: 0.4rem 0;
    position: absolute;
    top: 240px;
    width: 100%;
    box-shadow: 0 3px 6px #00000012;

    ul {
        display: flex;
        flex-direction: column;
    }

    .nav-item {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        font-family: 'CharlieText-Regular';
        list-style: none;
        padding: 0.4rem;
        margin: 1.2rem 0;
        cursor: pointer;
        color: #00B6CF;
        transition: 0.2s;

        span {

            align-self: center;
            margin: 0 0.3rem;
            font-size: 18px;
        }

        img {
            max-width: 35px;
            max-height: 35px;
        }
    }

    .active {
        font-family: 'CharlieText-Semibold';
        background: rgba(3, 159, 196, 0.25);
        border-radius: 0 30px 30px 0;
        padding: 1rem;
        border-left: 12px solid #039fc440;
        font-style: normal;
        font-weight: 700;
        font-size: 19px;
        color: #039FC4;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
    }
}



.output {
    justify-self: center;
    width: 100%;
    max-width: 900px;
    margin: 0 1rem;
    padding: 1.3rem;
    // background: linear-gradient(0deg, #F3FBFF, #F3FBFF), #C4C4C4;
    background-color: #fff !important;
    // border-radius: 30px;
    // box-shadow: 0 3px 6px #00000012;

    @include media("<phone") {
        width: 100%;
        margin: 0;
    }

    @include media(">phone", "<tablet") {
        width: 100%;
        margin: 0;
    }

    @include media(">tablet", "<custom-view") {
        width: 100%;
        margin: 0;
    }
}


// Themes

.software-theme {
    width: 100%;

    .logo {
        // background: linear-gradient(0deg, #E3EDFF, #E3EDFF), #C4C4C4;
        background: #fff;
    }

    .navigation {
        // background: linear-gradient(0deg, #E3EDFF, #E3EDFF), #C4C4C4;
        background: #fff;

        .nav-item {
            color: #0142AD;
        }

        .active {
            background: rgba(1, 66, 173, 0.25);
            border-left: 12px solid #0142AD;
            color: #0142AD;
        }
    }

    .output {
        // background: linear-gradient(0deg, #E3EDFF, #E3EDFF), #C4C4C4;
        background: #fff;
    }
}


.sdc-theme {
    .logo {
        // background: linear-gradient(0deg, #E3EDFF, #E3EDFF), #C4C4C4;
        background: #fff;
    }

    .navigation {
        // background: linear-gradient(0deg, #E3EDFF, #E3EDFF), #C4C4C4;
        background: #fff;

        .nav-item {
            color: #0142AD;
        }

        .active {
            background: rgba(1, 66, 173, 0.25);
            border-left: 12px solid #0142AD;
            color: #0142AD;
        }
    }

    .output {
        // background: linear-gradient(0deg, #E3EDFF, #E3EDFF), #C4C4C4;
        background: #fff;
    }
}

.scheme-theme {
    .logo {
        // background: linear-gradient(0deg, #E3EDFF, #E3EDFF), #C4C4C4;
        background: #fff;
    }

    .navigation {
        // background: linear-gradient(0deg, #E3EDFF, #E3EDFF), #C4C4C4;
        background: #fff;

        .nav-item {
            color: #0142AD;
        }

        .active {
            background: rgba(1, 66, 173, 0.25);
            border-left: 12px solid #0142AD;
            color: #0142AD;
        }
    }

    .output {
        // background: linear-gradient(0deg, #E3EDFF, #E3EDFF), #C4C4C4;
        background: #fff;
    }
}


@keyframes blink {

    0% {
        opacity: 1;
    }

    50% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }

}

@keyframes fadeOut {
    0% {
        opacity: 1;
    }

    50% {
        opacity: 0.5;
    }

    100% {
        opacity: 0;
        display: none;
    }
}


.main-view {
    background-color: rgba(0, 155, 192, 1);
    display: flex;
    // flex-grow: 1;
    width: 100%;
    height: 100vh;

    @media screen and (max-width: 769px) {
        display: block;
        position: relative;
    }

    .content-view {
        position: relative;
        width: 100vw;
        overflow-x: hidden;

        // @media screen and (max-width: 769px){
        //     position: static;
        //     height: 100vh;
        //     overflow-y: scroll;
        //     left: 0;
        //     right: 0;
        //     bottom: 0;
        // }
        @media screen and (max-width: 576px) {
            position: static;
            height: 100vh;
            overflow-y: scroll;
            left: 0;
            right: 0;
            bottom: 0;
        }

        .result-page {
            position: absolute;
            top: 0px;
            bottom: 15px;
            right: 0px;
            left: 10px;
            display: flex;
            justify-content: space-between;

            @media screen and (max-width: 769px) {
                display: block;
                position: relative;
                left: 0;
                right: 0;
                bottom: 0;
                overflow-y: scroll;
                height: 100vh;
            }

            .data-section {
                // width: 60vw;
                flex: 1;
                height: 100%;
                overflow-y: scroll;

                @media screen and (max-width: 769px) {
                    height: 100%;
                    width: 70vw;
                }

                @media screen and (max-width: 576px) {
                    width: 100vw;
                }
            }

            .right-end {
                width: 20vw;
                top: 0;
                right: 0;
                // bottom: 0;
                text-align: center;
                transition: all 1s ease-in;

                h2{
                    font-family: 'CharlieText-Bold';
                    font-size: 20px;
                }
                
                @media screen and (max-width: 769px) {
                    height: 100vh;
                    width: 30vw;
                    z-index: 1;
                    position: absolute;
                }
                
                @media screen and (max-width: 576px) {
                    height: 100vh;
                    width: 0px;
                    z-index: 1;
                    position: absolute;
                    padding: 0px;
                    // &>div{
                    //     display: none;
                    // }
                    
                    &.active {
                        width: 90%;
                        &>div{
                            display: block;
                        }

                        .close-menu-btn {
                            position: absolute;
                            top: 16px;
                            left: 15px;
                            width: 25px;
                            cursor: pointer;
    
                            .bar {
                                border: 2px solid #003850;
                                border-radius: 20px;
                                height: 2px;
                                width: 22px;
                                margin: auto;
                            }
    
                            .bar-1 {
                                transform: rotateZ(45deg) translateZ(24px);
                            }
    
                            .bar-3 {
                                transform: rotateZ(-45deg) translateX(3px) translateY(-2px);
                            }
    
                        }
                    }
                }
            }
        }

        .black-bg {
            position: absolute;
            width: 80vw;
            right: 10px;
            top: 15px;
            bottom: 5px;
            background-color: black;

            @media screen and (max-width: 769px) {
                display: none;
            }
        }
    }
}