.jira-service-desk-counts-body{
    .gGPPZn,
    .jeFWkz{
        justify-content: space-between;
        border-bottom: none !important;
        width: 100%;
    }

    .cNEsvO,
    .gvAKSE,
    .iHfLCz{
        justify-content: center;
        align-content: center;
        background: rgba(1, 66, 173, 0.25);
        border-radius: 30px;
        width: 20% ;
        border-bottom:3px solid #0142AD ;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 14px;
        color: #0142AD ;
    }

    .gvAKSE,
    .iHfLCz{
        margin-right: 20%;
    }

    .dTlZWA,
    .hSkvaW{
        justify-content: space-between;
        width: 100%;
        margin: 1rem 0;
        background: rgba(1, 66, 173, 0.25);
        border-radius: 30px;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        color: #0142AD;
        line-height: 14px;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
        border: none;
    }

    // .cyBftU{
    //     border-left:1px solid #E90001;
    // }
    
}